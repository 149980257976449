import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import MyApp from './App';
import {NextUIProvider} from "@nextui-org/react";

const root = ReactDOM.createRoot(document.getElementById('root')!);
root.render(
    <>
        <NextUIProvider>
            <MyApp/>
        </NextUIProvider>
    </>
)
